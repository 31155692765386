import React, { useContext } from 'react';
import './clients.scss';
import { ArrowForward } from '@material-ui/icons';
import { LangContext } from '../Layout';
import Img from 'gatsby-image';

const Clients = ({ client }) => {
  const { language } = useContext(LangContext);
  return (
    <div className="clients">
      <div className={'clients__header'}>
        <p className="clients__header__title">
          {client['headline_' + language]}
        </p>
        <a className={'clients__header__recommendation'} href="#recommendation">
          {client['recommendation_' + language]}
          <ArrowForward fontSize="small" className="materiel-icon" />
        </a>
      </div>
      <div className="clients__container clients__container__firstrow">
        {client.clients &&
          client.clients.map(
            (client, index) =>
              !client.title.includes('2') && (
                <img
                  src={client.logo.publicURL}
                  alt={client.title}
                  key={index}
                  className="clients__container__client"
                />
              ),
          )}
      </div>
      <div className="clients__container clients__container__secondrow">
        {client.clients &&
          client.clients.map(
            (client, index) =>
              client.title.includes('2') && (
                <img
                  src={client.logo.publicURL}
                  alt={client.title}
                  key={index}
                  className="clients__container__client"
                />
              ),
          )}
      </div>
    </div>
  );
};

export default Clients;
