import React, { useContext } from 'react';
import './mainpitch.scss';
import { Link } from 'gatsby';
import { ArrowForward } from '@material-ui/icons';
import { LangContext } from '../Layout';
import Img from 'gatsby-image';

export default function MainPitch({ mainpitch }) {
  const { language } = useContext(LangContext);
  const titleParts = mainpitch['title_' + language];

  return (
    <div className={'mainpitch'}>
      <div className={'mainpitch__left'}>
        <div className={'mainpitch__left__content'}>
          <h1 className={'mainpitch__left__content__title'}>
            {titleParts.part1 + ' '}
            <span className={'mainpitch__left__content__title__highlight'}>
              {titleParts.part2}
            </span>
            {' ' + titleParts.part3}
          </h1>
          <div className={'mainpitch__left__content__texte'}>
            {mainpitch['description_' + language]}
          </div>
          <Link to="/contact/#discuss">
            <button>
              Start my project
              <ArrowForward fontSize="small" className="materiel-icon" />
            </button>
          </Link>
        </div>
      </div>
      <div className={'mainpitch__right'}>
        <div className={'mainpitch__right__circ'}></div>
        <div className="mainpitch__right__mobile">
          <Img
            fluid={mainpitch.image.childImageSharp.fluid}
            alt="mainpitch"
            className={'mainpitch__right__image'}
          />
        </div>
        <div className="mainpitch__right__desktop">
          <Img
            fluid={mainpitch.image.childImageSharp.fluid}
            alt="mainpitch"
            className={'mainpitch__right__image'}
            style={{ position: 'abolute' }}
          />
        </div>
      </div>
    </div>
  );
}
