import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Clients from '../components/clients/Clients';
import MainPitch from '../components/mainpitch/mainpitch';
import loadable from '@loadable/component';

const ExpertiseComponent = loadable(() =>
  import('../components/uiExpertise/expertise'),
);
const MethodologyComponent = loadable(() =>
  import('../components/Methodology'),
);
const RecommandationComponent = loadable(() =>
  import('../components/recommendation/Recommandation'),
);
const BlogComponent = loadable(() => import('../components/Blog/blog'));

const CallToActionComponent = loadable(() =>
  import('../components/call-to-action/CallToAction'),
);

const ExpertsComponents = loadable(() =>
  import('../components/experts/experts'),
);
export const IndexPageTemplate = ({
  mainpitch,
  client,
  expertises,
  recommandations,
  blog,
  project,
  methodology,
  posts,
}) => (
  <div>
    {mainpitch && <MainPitch mainpitch={mainpitch} />}
    {client && <Clients client={client} />}
    {expertises && <ExpertiseComponent expertises={expertises} />}
    {methodology && <MethodologyComponent methodology={methodology} />}
    {recommandations && (
      <RecommandationComponent recommandations={recommandations} />
    )}
    {blog && <BlogComponent blog={blog} posts={posts} />}
    {project && <CallToActionComponent project={project} />}
  </div>
);

IndexPageTemplate.propTypes = {
  mainpitch: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { edges: posts } = data.allGhostPost;

  return (
    <>
      <Layout>
        <IndexPageTemplate
          mainpitch={frontmatter.mainpitch}
          client={frontmatter.client}
          expertises={frontmatter.expertises}
          recommandations={frontmatter.recommandations}
          methodology={frontmatter.methodology}
          partenaire={frontmatter.partenaire}
          blog={frontmatter.blog}
          project={frontmatter.project}
          posts={posts}
        />
      </Layout>
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        mainpitch {
          title_en {
            part1
            part2
            part3
          }
          title_fr {
            part1
            part2
            part3
          }
          description_en
          description_fr
          image {
            publicURL
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
          m33 {
            text_en
            text_fr
            logo {
              publicURL
            }
          }
        }
        blog {
          ticket_en
          ticket_fr
          title_en
          title_fr
          button_en
          button_fr
        }
        client {
          headline_en
          headline_fr
          recommendation_en
          recommendation_fr
          clients {
            logo {
              publicURL
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
            title
          }
        }
        expertises {
          headline_en
          headline_fr
          title_en
          title_fr
          description_en
          description_fr
          button_en
          button_fr
          content {
            image {
              publicURL
            }
            title_en
            title_fr
            url
            description_en
            description_fr
          }
        }
        methodology {
          headline_en
          headline_fr
          subHeadline_en
          subHeadline_fr
          description_en
          description_fr
          methodologies {
            logo {
              publicURL
            }
            title_en
            title_fr
            description_en
            description_fr
          }
        }
        project {
          title_en
          title_fr
          subtitle_en
          subtitle_fr
          button_en
          button_fr
          image {
            publicURL
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
        recommandations {
          headline_en
          headline_fr
          title_en
          title_fr
          description_en
          description_fr
          comment {
            logo {
              publicURL
            }
            avatar {
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
            description_en
            description_fr
            author
            position_en
            position_fr
          }
        }
      }
    }
    allGhostPost(sort: { order: DESC, fields: [published_at] }, limit: 3) {
      edges {
        node {
          id
          slug
          title
          html
          excerpt
          published_at
          image {
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
          feature_image
          authors {
            id
            name
            profile_image
            image {
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
          tags {
            name
          }
        }
      }
    }
  }
`;
